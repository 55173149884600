export const actionTypes = {
  GET_ORG_DETAILS_SUCCESS: 'GET_ORG_DETAILS_SUCCESS',
  GET_ORG_DETAILS_FAILURE: 'GET_ORG_DETAILS_FAILURE',
  GET_ORG_DETAILS_LOADER: 'GET_ORG_DETAILS_LOADER',
  CHANGE_ORG: 'CHANGE_ORG',
  GET_ONBOARD_SUCCESS: 'GET_ONBOARD_SUCCESS',
  GET_ONBOARD_FAILURE: 'GET_ONBOARD_FAILURE',
  GET_ONBOARD_LOADER: 'GET_ONBOARD_LOADER',
  POST_NEW_ONBOARD_SUCCESS: 'POST_NEW_ONBOARD_SUCCESS',
  POST_NEW_ONBOARD_FAILURE: 'POST_NEW_ONBOARD_FAILURE',
  POST_NEW_ONBOARD_LOADER: 'POST_NEW_ONBOARD_LOADER',
  GET_REPORT_TO_SUCCESS: 'GET_REPORT_TO_SUCCESS',
  GET_REPORT_TO_FAILURE: 'GET_REPORT_TO_FAILURE',
  OPEN_CASE_SUCCESS: 'OPEN_CASE_SUCCESS',
  OPEN_CASE_FAILURE: 'OPEN_CASE_FAILURE',

  NEW_ONBOARDING_SAVE_FAILURE: 'NEW_ONBOARDING_SAVE_FAILURE',
  NEW_ONBOARDING_SAVE_SUCCESS: 'NEW_ONBOARDING_SAVE_SUCCESS',
  NEW_ONBOARDING_SAVE_LOADER: 'NEW_ONBOARDING_SAVE_LOADER',

  SEARCH_EMP_LOADER: 'SEARCH_EMP_LOADER',

  VIEW_FAILURE: 'VIEW_FAILURE',
  VIEW_SUCCESS: 'VIEW_SUCCESS',
  VIEW_LOADER: 'VIEW_LOADER',

  I9_SAVE_SUCCESS: 'I9_SAVE_SUCCESS',
  I9_SAVE_FAILURE: 'I9_SAVE_FAILURE',
  I9_SAVE_LOADER: 'I9_SAVE_LOADER',

  I9_GET_SUCCESS: 'I9_GET_SUCCESS',
  I9_GET_FAILURE: 'I9_GET_FAILURE',
  I9_GET_LOADER: 'I9_GET_LOADER',

  SET_DATA_ONB: 'SET_DATA_ONB',

  CLEAR_DUPLICATES: 'CLEAR_DUPLICATES',

  SET_DATA_ONB_CASE_AUTHENTICATION: 'SET_DATA_ONB_CASE_AUTHENTICATION',

  RESEND_MAIL_SUCCESS: 'RESEND_MAIL_SUCCESS',
  RESEND_MAIL_FAILURE: 'RESEND_MAIL_FAILURE',

  CLEAR_OLD_EMP_DATA: 'CLEAR_OLD_EMP_DATA',

  GET_COMPENSATION_DETAILS_SUCCESS: 'GET_COMPENSATION_DETAILS_SUCCESS',
  GET_COMPENSATION_DETAILS_FAILURE: 'GET_COMPENSATION_DETAILS_FAILURE',
  GET_COMPENSATION_DETAILS_LOADER: 'GET_COMPENSATION_DETAILS_LOADER',

  WITHDRAW_SUCCESS: 'WITHDRAW_SUCCESS',
  WITHDRAW_FAILURE: 'WITHDRAW_FAILURE',
  WITHDRAW_LOADER: 'WITHDRAW_LOADER',

  DELETE_ATTACHMEMNT_SUCCESS: 'DELETE_ATTACHMEMNT_SUCCESS',
  DELETE_ATTACHMEMNT_FAILURE: 'DELETE_ATTACHMEMNT_FAILURE',
  DELETE_ATTACHMEMNT_LOADER: 'DELETE_ATTACHMEMNT_LOADER',

  OCR_ATTACHMEMNT_SUCCESS: 'OCR_ATTACHMEMNT_SUCCESS',
  OCR_ATTACHMEMNT_FAILURE: 'OCR_ATTACHMEMNT_FAILURE',
  OCR_ATTACHMEMNT_LOADER: 'OCR_ATTACHMEMNT_LOADER',

  CLEAR_OCR_DATA:'CLEAR_OCR_DATA'
};
