import { actionTypes } from '../actions/actionTypes/onBoardingActionType';

let initialState = {
  orgsWithDetails: [],
  userID: '',
  organisationsLoader: false,
  changeOrg: false,
  onboardingCases: [],
  onboardingCasesLoader: false,
  postNewOnboardingData: {},
  duplicateCase: [],
  postNewOnboardingLoader: false,
  reportToData: [],
  caseOpenData: {},
  SavedOnboardingCaseList: [],
  newOnBoardingSaveLoader: false,
  savedData: {},
  OffboardingCaseList: [],
  searchEmpLoader: false,
  OnboardSearchList: [],
  pagination: 0,
  QuickOnbSavedCasesCount: 0,
  DetailedOnbSavedCasesCount: 0,
  imgUrls: [],
  showLoaderForimages: false,
  DOB: '',
  SSN: '',
  viewLoader: false,
  I9CaseList: [],
  isOnboardingGetCallDone: false,
  ExistingEmployeeDetails: [],
  compensationDetails: {},
  futureCompensationDetails: {},
  currencyList: [],
  getCompensationDetailsLoader: false,
  AnnualBaseSalary: 0,
  IsExpenseCardEnabled: false,
  IsPayrollEnabled: false,
  OnboardingTypeList: [],
  NationalityList: [],
  AttachmentCateDetails: [],
  redirectToHome: false,
  onBoardingWithdrawLoader: false,
  Attachmentslist: [],
  deleteAttachmnetLoader: false,
  OnboardingOCRLoader: false,
  OCRDocumentsDetails: {},
};

/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function onBoarding(state = initialState, action) {
  switch (action.type) {
    case actionTypes.GET_ORG_DETAILS_SUCCESS:
      return {
        ...state,
        // orgsWithDetails: action.organisationsWithData.data,
        OnboardSearchList: action.organisationsWithData?.data?.OnboardSearchList,
        searchEmpLoader: false,
        organisationsLoader: false,
      };
    case actionTypes.GET_ORG_DETAILS_FAILURE:
      return {
        ...state,
        OnboardSearchList: [],
        searchEmpLoader: false,
        organisationsLoader: false,
      };

    case actionTypes.GET_ORG_DETAILS_LOADER:
      return {
        ...state,
        organisationsLoader: true,
      };

    case actionTypes.CHANGE_ORG:
      return {
        ...state,
        changeOrg: action.flag,
      };
    case actionTypes.GET_ONBOARD_SUCCESS:
      return {
        ...state,
        onboardingCases: action?.cases?.OnboardingCaseList || [],
        I9CaseList: action?.cases?.I9CaseList,
        SavedOnboardingCaseList: action.cases?.SavedOnboardingCaseList || [],
        OffboardingCaseList: action?.cases?.OffboardingCaseList || [],
        pagination: action.cases?.PageIndexCount || 0,
        QuickOnbSavedCasesCount: action.cases?.QuickOnbSavedCasesCount || 0,
        DetailedOnbSavedCasesCount: action.cases?.DetailedOnbSavedCasesCount || 0,
        searchEmpLoader: false,
        onboardingCasesLoader: false,
        redirectToHome: false,
        postNewOnboardingData: [],
        duplicateCase: [],
        caseOpenData: {},
        savedData: {},
        imgUrls: [],
        orgsWithDetails: action.cases,
        isOnboardingGetCallDone: true,
        AnnualBaseSalary: action?.cases?.AnnualBaseSalary
          ? parseFloat(action?.cases?.AnnualBaseSalary)
          : 0,
        IsExpenseCardEnabled: action.cases?.IsExpenseCardEnabled || false,
        IsPayrollEnabled: action.cases?.IsPayrollEnabled || false,
        OnboardingTypeList: action.cases?.OnboardingTypeList || [],
        NationalityList: action.cases?.CountryList || [],
        AttachmentCateDetails: action.cases?.AttachmentCateDetails || [],
        Attachmentslist: [],
      };
    case actionTypes.GET_ONBOARD_FAILURE:
      return {
        ...state,
        pagination: 0,
        isOnboardingGetCallDone: false,
        postNewOnboardingData: [],
        I9CaseList: [],
        onboardingCases: [],
        onboardingCasesLoader: false,
        duplicateCase: [],
        caseOpenData: {},
        searchEmpLoader: false,
        OffboardingCaseList: [],
      };
    case actionTypes.GET_ONBOARD_LOADER:
      return {
        ...state,
        isOnboardingGetCallDone: false,
        onboardingCasesLoader: true,
        redirectToHome: false,
      };
    case actionTypes.POST_NEW_ONBOARD_SUCCESS:
      return {
        ...state,
        postNewOnboardingData: action.cases,
        duplicateCase: action.cases.DuplicateCases,
        compensationDetails: action?.cases?.ExistingEmployeeDetails || [],
        ExistingEmployeeDetails: action?.cases?.ExistingEmployeeDetails || [],
        postNewOnboardingLoader: false,
      };
    case actionTypes.POST_NEW_ONBOARD_FAILURE:
      return {
        ...state,
        postNewOnboardingData: {},
        duplicateCase: [],
        ExistingEmployeeDetails: [],
        postNewOnboardingLoader: false,
      };
    case actionTypes.POST_NEW_ONBOARD_LOADER:
      return {
        ...state,
        postNewOnboardingLoader: true,
      };
    case actionTypes.GET_REPORT_TO_SUCCESS:
      return {
        ...state,
        reportToData: action.cases.data?.ReporttoList,
        organisationsLoader: false,
      };
    case actionTypes.GET_REPORT_TO_FAILURE:
      return {
        ...state,
        reportToData: [],
        organisationsLoader: false,
      };
    case actionTypes.OPEN_CASE_SUCCESS:
      return {
        ...state,
        caseOpenData: action.cases,
        isOnboardingGetCallDone: false,
        postNewOnboardingLoader: false,
        savedData: action.cases,
        Attachmentslist: action?.cases?.Attachmentslist || [],
        searchEmpLoader: false,
        DOB: '',
        SSN: '',
        viewLoader: false,
      };
    case actionTypes.OPEN_CASE_FAILURE:
      return {
        ...state,
        caseOpenData: {},
        isOnboardingGetCallDone: false,
        searchEmpLoader: false,
        postNewOnboardingLoader: false,
        DOB: '',
        SSN: '',
        viewLoader: false,
      };

    case actionTypes.NEW_ONBOARDING_SAVE_SUCCESS:
      return {
        ...state,
        SavedOnboardingCaseList: action?.cases.SavedOnboardingCaseList || [],
        savedData: action?.cases,
        duplicateCase: action?.cases.DuplicateCases || [],
        newOnBoardingSaveLoader: false,
        redirectToHome:
          action?.cases?.isDeleteAction ||
          action?.cases?.pyErrorPage?.pyStatus === 'FailDuplicate'
            ? false
            : true,
      };
    case actionTypes.NEW_ONBOARDING_SAVE_FAILURE:
      return {
        ...state,
        newOnBoardingSaveLoader: false,
        savedData: {},
        redirectToHome: false,
      };
    case actionTypes.NEW_ONBOARDING_SAVE_LOADER:
      return {
        ...state,
        newOnBoardingSaveLoader: true,
      };
    case actionTypes.SEARCH_EMP_LOADER:
      return {
        ...state,
        searchEmpLoader: true,
      };
    case 'VIEW_RECEIPT_SUCCESS':
      return {
        ...state,
        imgUrls: action.cases,
        showLoaderForimages: false,
      };
    case 'VIEW_RECEIPT_FAILURE':
      return {
        ...state,
        imgUrls: [],
        showLoaderForimages: false,
      };

    case 'VIEW_RECEIPT_LODER':
      return {
        ...state,
        showLoaderForimages: true,
      };

    case actionTypes.VIEW_SUCCESS:
      return {
        ...state,
        DOB: action.cases?.BasicPartyDetails?.DOB,
        SSN: action.cases?.BasicPartyDetails?.SSN,
        viewLoader: false,
        userID: action.cases?.BasicPartyDetails?.ID,
      };
    case actionTypes.VIEW_FAILURE:
      return {
        ...state,
        DOB: '',
        SSN: '',
        userID: '',
        viewLoader: false,
      };
    case actionTypes.VIEW_LOADER:
      return {
        ...state,
        viewLoader: true,
      };

    case actionTypes.SET_DATA_ONB:
      return {
        ...state,
        postNewOnboardingData: {},
        duplicateCase: [],
        isOnboardingGetCallDone: false,
        caseOpenData: {},
        savedData: {},
        Attachmentslist: [],
        OCRDocumentsDetails: {},
      };

    case actionTypes.CLEAR_OCR_DATA:
      return {
        ...state,
        OCRDocumentsDetails: {},
      };

    case actionTypes.CLEAR_DUPLICATES:
      return {
        ...state,
        postNewOnboardingData: {},
        duplicateCase: [],
        isOnboardingGetCallDone: false,
        caseOpenData: {},
        // savedData: {},
      };

    case actionTypes.CLEAR_OLD_EMP_DATA:
      return {
        ...state,
        ExistingEmployeeDetails: [],
      };

    case actionTypes.SET_DATA_ONB_CASE_AUTHENTICATION:
      return {
        ...state,
        caseOpenData: {},
      };

    case 'RESET_VIEWDATA':
      return {
        ...state,
        DOB: '',
        SSN: '',
        userID: '',
        viewLoader: false,
      };
    case actionTypes.RESEND_MAIL_SUCCESS:
      return {
        ...state,
        viewLoader: false,
      };
    case actionTypes.RESEND_MAIL_FAILURE:
      return {
        ...state,
        viewLoader: false,
      };
    case actionTypes.GET_COMPENSATION_DETAILS_SUCCESS:
      return {
        ...state,
        getCompensationDetailsLoader: false,
        compensationDetails: action.cases.CompensationDetails || {},
        futureCompensationDetails: action.cases.FutureCompensationDetails || {},
        currencyList: action.cases.CurrencyList || [],
        currentLocationCountryCode: action.cases.CurrentLocationCountryCode || '',
      };
    case actionTypes.GET_COMPENSATION_DETAILS_FAILURE:
      return {
        ...state,
        getCompensationDetailsLoader: false,
      };
    case actionTypes.GET_COMPENSATION_DETAILS_LOADER:
      return {
        ...state,
        getCompensationDetailsLoader: true,
      };
    case actionTypes.WITHDRAW_SUCCESS:
      return {
        ...state,
        onBoardingWithdrawLoader: false,
      };
    case actionTypes.WITHDRAW_FAILURE:
      return {
        ...state,
        onBoardingWithdrawLoader: false,
      };
    case actionTypes.WITHDRAW_LOADER:
      return {
        ...state,
        onBoardingWithdrawLoader: true,
      };

    case actionTypes.DELETE_ATTACHMEMNT_SUCCESS:
      return {
        ...state,
        deleteAttachmnetLoader: false,
        Attachmentslist: action?.cases?.Attachmentslist || [],
      };
    case actionTypes.DELETE_ATTACHMEMNT_FAILURE:
      return {
        ...state,
        deleteAttachmnetLoader: false,
      };
    case actionTypes.DELETE_ATTACHMEMNT_LOADER:
      return {
        ...state,
        deleteAttachmnetLoader: true,
      };

    case actionTypes.OCR_ATTACHMEMNT_SUCCESS:
      return {
        ...state,
        OnboardingOCRLoader: false,
        OCRDocumentsDetails: action?.cases?.OCRDocumentsDetails || {},
      };
    case actionTypes.OCR_ATTACHMEMNT_FAILURE:
      return {
        ...state,
        OnboardingOCRLoader: false,
      };
    case actionTypes.OCR_ATTACHMEMNT_LOADER:
      return {
        ...state,
        OnboardingOCRLoader: true,
      };
    default:
      return state;
  }
}
