import { actionTypes } from '../actions/actionTypes/onBoardingActionType';

let initialState = {
  postI9Loader: false,
  i9SavedData: {},
  getI9Loader: false,
};

/**
 * Redux reducers.
 * Used to update state in the store after actions are issued.
 */
export function i9(state = initialState, action) {
  switch (action.type) {
    case actionTypes.I9_SAVE_SUCCESS:
      return {
        ...state,
        postI9Loader: false,
        i9SavedData: action.cases,
      };

    case actionTypes.I9_SAVE_FAILURE:
      return {
        ...state,
        postI9Loader: false,
        i9SavedData: {},
      };

    case actionTypes.I9_SAVE_LOADER:
      return {
        ...state,
        postI9Loader: true,
      };

    case actionTypes.I9_GET_SUCCESS:
      return {
        ...state,
        getI9Loader: false,
        i9SavedData: action.cases,
      };

    case actionTypes.I9_GET_FAILURE:
      return {
        ...state,
        getI9Loader: false,
      };

    case actionTypes.I9_GET_LOADER:
      return {
        ...state,
        getI9Loader: true,
      };

    default:
      return state;
  }
}
